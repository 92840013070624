import { buildApiClient } from './helpers';

const apiClient = buildApiClient();

function fetch() {
  return apiClient.get('offer');
}

function add(data) {
  return apiClient.post('offer', data);
}

function update(id, data) {
  return apiClient.patch(`offer/${id}`, data);
}

function getById(id) {
  return apiClient.get(`offer/${id}`);
}

function deleteById(id) {
  return apiClient.delete(`offer/${id}`);
}

function addOfferPublicReview(guid, data) {
  return apiClient.post(`offer/save-public-review/${guid}`, data);
}

function sendPushNotification(id) {
  return apiClient.post(`offer/send-push-notification/${id}`, {});
}

function refreshShipmentStatus(id) {
  return apiClient.post(`offer/refresh-shipment-status/${id}`, {});
}

export const offerApi = {
  fetch,
  add,
  update,
  getById,
  deleteById,
  addOfferPublicReview,
  sendPushNotification,
  refreshShipmentStatus,
};
