import { buildApiClient } from './helpers';

const apiClient = buildApiClient();

function fetch() {
  return apiClient.get('user');
}

function add(data) {
  return apiClient.post('user', data);
}

function update(id, data) {
  return apiClient.patch(`user/${id}`, data);
}

function getById(id) {
  return apiClient.get(`user/${id}`);
}

function deleteById(id) {
  return apiClient.delete(`user/${id}`);
}

function deleteHistoryById(id) {
  return apiClient.delete(`user/history/${id}`);
}

export const userApi = {
  fetch,
  add,
  update,
  getById,
  deleteById,
  deleteHistoryById,
};
