import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Delete from '@material-ui/icons/Delete';

const OfferRow = ({ item, index, handleChange, handleDelete }) => {
  return (
    <div className="d-flex mb-2 gap-16">
      <div className="flex-grow-1">
        <TextField
          size="small"
          required
          fullWidth
          autoComplete="off"
          name="name"
          value={item.name || ''}
          onChange={handleChange(index, 'name')}
        />
      </div>
      <div style={{ flex: '0 0 200px' }}>
        <TextField
          size="small"
          fullWidth
          autoComplete="off"
          name="category"
          value={item.category || ''}
          onChange={handleChange(index, 'category')}
        />
      </div>
      <div style={{ flex: '0 0 100px' }}>
        <TextField
          size="small"
          fullWidth
          required
          inputProps={{
            className: 'text-right',
          }}
          placeholder="0"
          value={item.quantity || ''}
          onChange={handleChange(index, 'quantity')}
        />
      </div>

      <div className="text-right" style={{ flex: '0 0 40px' }}>
        <IconButton size="small" title="Sterge" onClick={handleDelete(index)} tabIndex={-1}>
          <Delete />
        </IconButton>
      </div>
    </div>
  );
};

const OfferItems = ({ items, onChange }) => {
  const handleChange = (index, name) => (event) => {
    const value = typeof event === 'string' ? event : event.target.value;

    const newItems = [...items];
    newItems[index] = { ...newItems[index], [name]: value };

    onChange([...newItems]);
  };

  const handleAddNew = () => {
    onChange([...items, { id: null, name: '', quantity: '' }]);
  };

  const handleDelete = (index) => () => {
    const newItems = [...items];
    newItems.splice(index, 1);

    onChange(newItems);
  };

  return (
    <div className="items-list">
      <div className="d-flex my-2 font-weight-bolder gap-16">
        <Typography className="flex-grow-1" variant="overline">
          Nume produs
        </Typography>
        <Typography variant="overline" style={{ flex: '0 0 200px' }}>
          Categorie
        </Typography>
        <Typography className="text-right" variant="overline" style={{ flex: '0 0 100px' }}>
          Cantitate
        </Typography>
        <div style={{ flex: '0 0 40px' }} />
      </div>

      {items.map((item, index) => (
        <OfferRow
          key={`item-row-${item.name}-${item.category}-${item.quantity}`}
          item={item}
          index={index}
          handleChange={handleChange}
          handleDelete={handleDelete}
        />
      ))}

      <Button variant="text" color="primary" onClick={handleAddNew} startIcon={<i className="fal fa-plus" />}>
        Adauga
      </Button>
    </div>
  );
};

export default OfferItems;
