import { alpha } from '@material-ui/core/styles/colorManipulator';
import theme from '../../../theme/theme';

export const drawerWidth = 320;
export const styles = () => ({
  root: {
    display: 'flex',
    background: '#f5f6fa',
  },
  drawer: {
    width: drawerWidth,
    whiteSpace: 'nowrap',
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: 0,
    [theme.breakpoints.up('sm')]: {
      top: 0,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'hidden',
    width: 0,
  },

  fadeTransitionEnter: {
    opacity: 0,
    zIndex: '1',
    display: 'block',
  },
  fadeTransitionEnterActive: {
    opacity: 1,
    transition: 'opacity 250ms ease-in',
  },
  fadeTransitionExit: {
    opacity: 0,
    display: 'none',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 0 15px',
    borderBottom: '1px solid #E5E5E7',
    margin: '0 24px 15px',
  },
  logoImg: {
    maxWidth: 200,
    marginRight: 8,
  },
  logoText: {
    fontWeight: 300,
    fontSize: '30px',
    marginTop: '-2px',
    color: theme.palette.brand,
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(1, 3, 3),
    minHeight: '100vh',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 2, 4),
    },
    transition: theme.transitions.create(['padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },

  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'block',
    /* [theme.breakpoints.up('sm')]: {
            display: 'block',
        }, */
  },
  pageTitle: {
    display: 'block',
    margin: theme.spacing(2, 1),

    [theme.breakpoints.down(1024)]: {
      /* display: 'none', */
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  siteTitle: {
    color: '#fff',
    /* fontWeight: '500', */
    fontSize: 26,
    textAlign: 'center',
    width: '100%',
    /* textTransform: 'uppercase', */
    /* textShadow: '1px 1px 2px #6d0012', */
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  changerAction: {
    marginTop: '0',
    padding: '12px 12px 12px 56px',
    minHeight: '48px',
    display: 'block',
    color: '#313534',
    textDecoration: 'none',
    fontSize: '15px',
  },

  userMeta: {
    color: '#313534',
    position: 'relative',
    margin: theme.spacing(2, 1, 1, 1),
  },

  profileInfo: {
    display: 'inline-block',
    verticalAlign: 'middle',
    padding: '0 20px 0 10px',
    lineHeight: '15px',
  },

  small: {
    display: 'block',
    opacity: '0.5',
  },
});
