import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import { Table, TableBody, TableCell, TablePagination, TableRow, Paper, Checkbox } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EnhancedTableHead from './EnhancedTableHead';
import ConfirmationDialog from '../confirmation-dialog';
import TableFilters from './TableFilters';

const getValue = (path, object) => path.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), object);

function desc(a, b, orderBy) {
  let aValue = a[orderBy];
  let bValue = b[orderBy];

  if (orderBy.indexOf('.') !== false) {
    aValue = getValue(orderBy.split('.'), a) || '';
    bValue = getValue(orderBy.split('.'), b) || '';
  }

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: 0,
    marginBottom: theme.spacing(6),
  },
  actions: {
    flexGrow: 1,
  },
  filters: {
    justifySelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifySelf: 'flex-start',
      marginBottom: theme.spacing(2),
    },
  },
  inactiveRow: {
    opacity: 0.7,
    background: '#f2f2f2',
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableRow: {
    cursor: 'pointer',
  },
  toolbar: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  },
  rowDescription: {
    display: 'block',
    color: '#8a8a8a',
    marginTop: '2px',
  },
});

@withStyles(styles)
class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteConfirmation: false,
      order: props.order ? props.order : 'asc',
      orderBy: props.orderBy ? props.orderBy : 'id',
      selected: [],
      data: props.data ? props.data : [],
      page: 0,
      rowsPerPage: 10,
    };
  }

  componentWillReceiveProps(newProps) {
    const { data } = newProps;

    // Figure out page
    let { page, rowsPerPage } = this.state;
    page = page * rowsPerPage < data.length ? page : 0;

    this.setState({ data, page });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const pageData = this.getPageData();
      this.setState({ selected: pageData.map((n) => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    // Scroll app container to top
    const content = document.getElementById('App-content');
    if (content) {
      document.getElementById('App-content').scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }

    // Change page
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  confirmMassDelete = () => {
    const { onMassDelete } = this.props;
    const { selected } = this.state;

    if (selected && selected.length) {
      onMassDelete(selected);
    }

    this.setState({ deleteConfirmation: false, selected: [] });
  };

  triggerMassDelete = () => {
    this.setState({ deleteConfirmation: true });
  };

  cancelMassDelete = () => this.setState({ deleteConfirmation: false });

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  getPageData = () => {
    const { data, order, orderBy, rowsPerPage, page } = this.state;
    return stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  render() {
    const {
      classes,
      columns,
      isRowActive = null,
      filters,
      noCheckbox,
      noToolbar,
      onRowClick,
      size = 'medium',
      toolbarActions,
    } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page, deleteConfirmation } = this.state;

    const pageData = this.getPageData();

    return (
      <Paper className={classes.root} elevation={0}>
        {!noToolbar && (
          <div className={classes.toolbar}>
            <div className={classes.actions}>
              {toolbarActions}
              {selected && selected.length ? (
                <Button
                  variant="text"
                  className={classes.button}
                  onClick={this.triggerMassDelete}
                  startIcon={<i className="fal fa-trash" />}>
                  Sterge
                </Button>
              ) : null}
            </div>
            {filters && (
              <div className={classes.filters}>
                <TableFilters filters={filters} />
              </div>
            )}
          </div>
        )}
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle" size={size}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              pageRowCount={pageData.length}
              noCheckbox={noCheckbox}
              columns={columns}
            />
            <TableBody>
              {pageData.map((row) => {
                const isSelected = this.isSelected(row.id);
                let inactiveRow = false;
                if (isRowActive) {
                  inactiveRow = !isRowActive(row);
                }

                // Loop through columns and render values
                const rowCells = columns.map((col, idx) => {
                  // Retrieve row value
                  let output = col.renderValue && col.renderValue(row, idx);
                  if (typeof output === 'undefined') {
                    output = row[col.accessor ? col.accessor : col.id];
                    output = typeof output === 'string' || typeof output === 'number' ? output : null;
                  }

                  return (
                    <TableCell
                      onClick={() => {
                        if (col.onClick) {
                          col.onClick(row);
                        } else if (onRowClick) {
                          onRowClick(row);
                        }
                      }}
                      component={col.component ? col.component : 'td'}
                      scope={col.scope ? col.scope : null}
                      key={`cell-${row.id ? row.id.toString() : '--'}-${col.id}-${col.accessor}`}
                      padding={col.padding ? col.padding : 'normal'}
                      align={col.align ? col.align : col.numeric ? 'right' : 'left'}
                      style={col.style}>
                      {output}
                    </TableCell>
                  );
                });

                return (
                  <TableRow
                    hover
                    className={[classes.tableRow, inactiveRow ? classes.inactiveRow : ''].join(' ')}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={`row-${row.id.toString()}`}
                    selected={isSelected}>
                    {noCheckbox ? null : (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected}
                          onChange={(event) => (noCheckbox ? null : this.handleClick(event, row.id))}
                        />
                      </TableCell>
                    )}
                    {rowCells}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />

        {!deleteConfirmation ? null : (
          <ConfirmationDialog
            title="Confirmare stergere"
            message={`Esti sigur ca vrei sa stergi datele selectate (${selected.length} ${
              selected.length === 1 ? 'rand' : 'randuri'
            })?`}
            confirm={this.confirmMassDelete}
            cancel={this.cancelMassDelete}
            show
          />
        )}
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  columns: PropTypes.instanceOf(Array).isRequired,
};

export default EnhancedTable;
