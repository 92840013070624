import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Typography,
  TableBody,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  TableCell,
  Button,
  Link,
} from '@material-ui/core';

import { Prompt, withRouter } from 'react-router';
import _ from 'lodash';
import { userApi } from '../../../api/user';
import ContentPaper from '../../../components/content-paper';
import { translate } from '../../../i18n';
import LoadingText from '../../../components/loading-text';
import FormActions from '../../../components/form-actions';
import { parseApiError } from '../../../api/helpers';
import { FormContainer } from '../../../components/forms/container';
import { FormRow } from '../../../components/forms/row';
import { PasswordHelper } from '../../../lib/password-helper';
import UserChannels from './user-channels';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  buttonBlock: {
    display: 'block',
    width: '140px',
    margin: '0 auto',
    marginBottom: theme.spacing(1),
  },
  buttonDelete: {
    margin: theme.spacing(1),
    color: theme.palette.error.dark,
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },

  helperText: {
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  imagePreview: {
    width: '100px',
    height: '100px',
    backgroundColor: '#fafafa',
    margin: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  img: {
    width: 'auto',
    maxHeight: '90%',
    maxWidth: '90%',
    marginTop: '50%',
    transform: 'translateY(-50%)',
  },
});

@withStyles(styles)
@withRouter
class UserForm extends Component {
  constructor(props) {
    super(props);

    // Set page title
    const { id, setPageTitle } = this.props;
    let pageTitle = translate('Adauga cont');
    if (id) {
      pageTitle = translate('Modifica cont');
    }
    setPageTitle(pageTitle);

    this.state = {
      id: props.id ? props.id : null,
      data: !_.isEmpty(props.data)
        ? props.data
        : {
            username: '',
            password: PasswordHelper.generate(12),
            new_password: '',
          },
    };
  }

  componentDidMount() {
    const { id, fetchChannels, fetchUserById } = this.props;

    if (id) {
      fetchUserById(id);
    }

    fetchChannels();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    // const { id, data } = this.state;

    if (nextProps.id) {
      this.setState({
        id: nextProps.id,
        data: nextProps.data,
      });
    }
  }

  handleSubmit = async (ev) => {
    if (ev) {
      ev.preventDefault();
    }

    const { data, id } = this.state;
    const { enqueueSnackbar, onSave } = this.props;

    try {
      const response = !id ? await userApi.add(data) : await userApi.update(id, data);

      if (!response.ok) {
        enqueueSnackbar(parseApiError(response).message, { options: { variant: 'error' } });
        return;
      }

      this.setState({ hasUnsavedChanges: false });
      enqueueSnackbar('Utilizator salvat');

      if (onSave) {
        onSave(response);
      }

      this.handleAfterSave();
    } catch (e) {
      enqueueSnackbar('A aparut o eroare la salvarea utilizatorului', { options: { variant: 'error' } });
    }
  };

  handleAfterSave = () => {
    const { history } = this.props;
    history.push('/user');
  };

  handleDelete = async () => {
    const { id } = this.state;
    const { enqueueSnackbar, history } = this.props;

    // Reset state
    this.setState({ hasUnsavedChanges: false });

    // Delete AJAX
    try {
      await userApi.deleteById(id);

      enqueueSnackbar('Utilizator sters');
      history.push('/user');
    } catch (e) {
      enqueueSnackbar('Utilizatorul nu a putut fi sters', { options: { variant: 'error' } });
    }
  };

  handleChange = (name, value) => (event) => {
    const { data } = this.state;
    value = typeof value !== 'undefined' ? value : event.target.value;
    this.setState({ data: { ...data, [name]: value }, hasUnsavedChanges: true });
  };

  handleChangeEmail = (name, value) => (event) => {
    const { id, data } = this.state;
    value = typeof value !== 'undefined' ? value : event.target.value;
    const newData = {
      email_address: value,
    };

    if (!id) {
      newData.username = value;
    }

    this.setState({ data: { ...data, ...newData }, hasUnsavedChanges: true });
  };

  handleMassChannels = (unchecked, checked) => {
    const { data } = this.state;
    let { channels = [] } = data;

    for (const channel of unchecked) {
      // Remove channel from user
      channels = (channels || []).filter((c) => c.id !== channel.id);
    }
    for (const channel of checked) {
      if ((channels || []).findIndex((c) => c.id === channel.id) === -1) {
        channels = [...channels, channel];
      }
    }

    this.setState({ data: { ...data, channels } });
  };

  handleDeleteHistory = async (id) => {
    const { enqueueSnackbar, history } = this.props;

    // Delete AJAX
    try {
      await userApi.deleteHistoryById(id);

      enqueueSnackbar('Istoric lucrare sters cu succes');
      history.go(0);
    } catch (e) {
      enqueueSnackbar('Istoricul nu a putut fi sters', { options: { variant: 'error' } });
    }
  };

  shouldComponentRender = () => {
    const { id, data } = this.state;

    if (id && !data) {
      return false;
    }

    return true;
  };

  canSubmitForm = () => {
    const { data } = this.state;
    // eslint-disable-next-line camelcase
    const { first_name, last_name, username, email_address, role } = data;

    // eslint-disable-next-line camelcase
    return first_name && last_name && username && email_address && role;
  };

  render() {
    const { classes, channels } = this.props;
    const { id, data, hasUnsavedChanges } = this.state;

    if (!this.shouldComponentRender()) {
      return <LoadingText />;
    }

    const username = data && data.username ? data.username : '';

    return (
      <>
        <Prompt
          when={!!hasUnsavedChanges}
          message={translate('Modificarile nu sunt salvate. Esti sigur ca vrei sa continui?')}
        />
        <Typography variant="h5" component="h2" className="mb-5">
          {!id ? 'Adauga cont' : 'Modifica cont'}
        </Typography>

        <Typography variant="h6" className="mb-2">
          Informatii personale
        </Typography>
        <ContentPaper className="FormBoxPaper mb-3">
          <FormContainer>
            <FormRow>
              <TextField
                required
                name="first_name"
                label="Nume"
                className={classes.textField}
                value={data.first_name || ''}
                onChange={this.handleChange('first_name')}
              />
              <TextField
                required
                name="last_name"
                label="Prenume"
                className={classes.textField}
                value={data.last_name || ''}
                onChange={this.handleChange('last_name')}
              />
            </FormRow>
          </FormContainer>

          <FormContainer>
            <FormRow>
              <TextField
                required
                name="email"
                label="Email"
                className={classes.textField}
                value={data.email_address || ''}
                onChange={this.handleChangeEmail('email_address')}
              />
              <TextField
                name="phone_number"
                label="Telefon"
                className={classes.textField}
                value={data.phone_number || ''}
                onChange={this.handleChange('phone_number')}
              />
            </FormRow>
          </FormContainer>

          <FormContainer>
            <FormRow cols={2}>
              <TextField
                name="company_name"
                label="Nume firma"
                className={classes.textField}
                value={data.company_name || ''}
                onChange={this.handleChange('company_name')}
              />
              <TextField
                name="company_code"
                label="CUI"
                className={classes.textField}
                value={data.company_code || ''}
                onChange={this.handleChange('company_code')}
              />
            </FormRow>
          </FormContainer>

          <FormContainer>
            <FormRow cols={2}>
              <TextField
                name="magento_user_id"
                label="ID user site"
                className={classes.textField}
                value={data.magento_user_id || ''}
                onChange={this.handleChange('magento_user_id')}
                helperText="ID-ul utilizatorului din site"
              />
            </FormRow>
          </FormContainer>
        </ContentPaper>

        <Typography variant="h6" className="mb-2">
          Detalii cont
        </Typography>
        <ContentPaper className="FormBoxPaper mb-3">
          <FormContainer>
            <FormRow>
              <TextField
                required
                autoComplete="off"
                name="username"
                label="Utilizator"
                className={classes.textField}
                value={username || ''}
                onChange={this.handleChange('username')}
              />

              {data.role === 'owner' ? null : (
                <FormControl variant="outlined" className={classes.formControl} required>
                  <InputLabel id="role-label-outlined">Tip utilizator</InputLabel>
                  <Select
                    label="Tip utilizator"
                    value={data.role || ''}
                    onChange={this.handleChange('role')}
                    inputProps={{
                      name: 'role',
                      id: 'role-label-placeholder',
                    }}
                    displayEmpty
                    labelId="role-label-outlined"
                    id="role"
                    name="role">
                    <MenuItem value="user">Instalator</MenuItem>
                    <MenuItem value="manager">Account Manager</MenuItem>
                    <MenuItem value="admin">Administrator</MenuItem>
                  </Select>
                </FormControl>
              )}
            </FormRow>
          </FormContainer>

          <FormContainer>
            <FormRow cols={2}>
              {!id ? (
                <TextField
                  disabled
                  autoComplete="off"
                  label="Parola"
                  className={classes.textField}
                  value={data.password}
                  type="text"
                  helperText="Parola este generata automat si nu poate fi schimbata."
                />
              ) : (
                <TextField
                  required
                  autoComplete="off"
                  name="password"
                  label="Parola"
                  className={classes.textField}
                  value={data.new_password || ''}
                  onChange={this.handleChange('new_password')}
                  type="text"
                  helperText="Introdu o parola pentru a o schimba pe cea veche."
                />
              )}
            </FormRow>
          </FormContainer>
        </ContentPaper>

        <Typography variant="h6" className="mb-2">
          Regiuni
        </Typography>
        <ContentPaper
          className="FormBoxPaper mb-1"
          elevation={0}
          style={{ background: 'transparent', paddingLeft: 0, paddingRight: 0 }}>
          <UserChannels channels={channels} selected={data.channels} onChange={this.handleMassChannels} />
        </ContentPaper>

        <Typography variant="h6" className="mb-2">
          Lucrari blocate
        </Typography>

        <TableContainer component={ContentPaper}>
          {data.offer_history && data.offer_history.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Lucrare</TableCell>
                  <TableCell>Actiune</TableCell>
                  <TableCell>Motiv</TableCell>
                  <TableCell align="center">Data anulare</TableCell>
                  <TableCell align="right">&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.offer_history.map((history) => (
                  <TableRow key={history.id}>
                    <TableCell>
                      <Link
                        onClick={(event) => event.stopPropagation()}
                        href={`/offer/edit/${history.offer_id}`}
                        target="_blank">
                        Deschide
                      </Link>
                    </TableCell>
                    <TableCell className="font-weight-bold">{history.action === 'cancel' ? 'Anulare' : '-'}</TableCell>
                    <TableCell>{history.notes || '-'}</TableCell>
                    <TableCell align="center">{new Date(history.created_at).toLocaleString()}</TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => {
                          this.handleDeleteHistory(history.id);
                        }}>
                        Sterge
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography className="text-muted p-2">Nici o lucrare blocata</Typography>
          )}
        </TableContainer>

        <FormActions
          onDelete={this.handleDelete}
          onCancel={this.handleAfterSave}
          onSubmit={this.handleSubmit}
          disableSubmit={!this.canSubmitForm()}
        />
      </>
    );
  }
}

export default UserForm;
