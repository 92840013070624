import React from 'react';

import { withStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    minHeight: 36,
  },
});

@withStyles(styles)
class LoadingText extends React.Component {
  render() {
    const { classes, size = 24 } = this.props;
    /* <Typography component="p" classes={classes}>Loading...</Typography> */

    return (
      <div className={classes.root}>
        <CircularProgress variant="indeterminate" size={size} color="secondary" />
      </div>
    );
  }
}

export default LoadingText;
